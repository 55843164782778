import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack,
  Container,
  Typography,
  TextField,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Switch,
  FormHelperText,
  Button,
  Autocomplete, 
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import countries from 'i18n-iso-countries';
import russianLocale from 'i18n-iso-countries/langs/ru.json';
import Iconify from '../components/iconify';
import api from '../api/api';
import useFetch from '../hooks/useFetch';



countries.registerLocale(russianLocale);

const options = Object.entries(countries.getNames('ru')).map(([code, label]) => ({
  code,
  label
}));

const allCountriesOption = { code: 'ALL', label: 'Все страны' };

const countryOptions = [allCountriesOption, ...options];

const EditCrmSettingPage = () => {
  const [initialValues, setInitialValues] = useState({
    working_hours_start: '',
    working_hours_end: '',
    working_days: [],
    daily_cap: 0,
    is_active: false,
    skip_after_workings: false,
    skip_duplicate: false,
    generate_email_if_missing: false,
    countries: [],
    statuses: []
  });
  const [currentSettingsId, setCurrentSettingsId] = useState(0);
  const [crmName, setCrmName] = useState('');
  const { crmId } = useParams();
  const navigate = useNavigate();

  const { isLoading, error, data, refetch } = useFetch(`api/crms/settings/${crmId}`, 'get');

  useEffect(() => {
    if (data) {
     
      setCrmName(data.crmName);
      if(data.isRelation){
        setCurrentSettingsId(data.id);
        setInitialValues({
          working_hours_start: data.working_hours_start || '',
          working_hours_end: data.working_hours_end || '',
          working_days: data.working_days || [],
          daily_cap: data.daily_cap || 0,
          is_active: data.is_active || false,
          skip_duplicate: data.skip_duplicate || false,
          skip_after_workings: data.skip_after_workings || false,
          generate_email_if_missing: data.generate_email_if_missing || false,
          phone_with_plus: data.phone_with_plus || false,
          countries: data.countries || [],
          statuses: data.statuses || []
        });
      }
      
    }
  }, [data]);


  const updateCrm = async (values) => {
    try {
      await api.put(`api/crms/settings/${currentSettingsId}`, values);
      navigate('/dashboard/crms');
      return null;
    } catch (error) {
      return error.response.data.errors;
    }
  };

  const validationSchema = Yup.object().shape({
    working_hours_start: Yup.string().required('Время начала обязательно'),
    working_hours_end: Yup.string().required('Время конца обязательно'),
    daily_cap: Yup.number()
      .required('Укажите капу в день')
      .positive('Капа должна быть положительным числом')
      .integer('Капа должна быть целым числом'),
    is_active: Yup.boolean(),
    phone_with_plus: Yup.boolean(),
    working_days: Yup.array().min(1, 'Выберите хотя бы один рабочий день'),
    countries: Yup.array().of(Yup.string()).min(1, 'Выберите хотя бы одну страну'),
  });

  if (isLoading && !data) {
    return (
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ height: 'calc(100vh - 200px)' }}>
          <CircularProgress />
        </Stack>
      </Container>
    );
  }


  if (error) {
    return (
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ height: 'calc(100vh - 200px)' }}>
          <Typography variant="subtitle2" noWrap>
            {error}
          </Typography>
          <Button onClick={refetch}>Обновить</Button>
        </Stack>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>Настройки Срм</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Настройки {crmName}
          </Typography>
        </Stack>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            values.crmId = crmId;
            const errors = await updateCrm(values);
            if (errors) {
              setErrors(errors);
            }
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <Stack spacing={3}>
                <Field name="working_hours_start">
                  {({ field, form }) => (
                    <TextField
                      {...field}
                      placeholder="Время начала"
                      label="Время начала"
                      type="time"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      error={Boolean(form.errors.working_hours_start && form.touched.working_hours_start)}
                      helperText={
                        form.errors.working_hours_start && form.touched.working_hours_start
                          ? form.errors.working_hours_start
                          : null
                      }
                    />
                  )}
                </Field>
                <Field name="working_hours_end">
                  {({ field, form }) => (
                    <TextField
                      {...field}
                      label="Время конца"
                      variant="outlined"
                      type="time"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={Boolean(form.errors.working_hours_end && form.touched.working_hours_end)}
                      helperText={
                        form.errors.working_hours_end && form.touched.working_hours_end
                          ? form.errors.working_hours_end
                          : null
                      }
                    />
                  )}
                </Field>
                <Field name="daily_cap">
                  {({ field, form }) => (
                    <TextField
                      {...field}
                      label="Капа в день"
                      variant="outlined"
                      fullWidth
                      type="number"
                      error={Boolean(form.errors.daily_cap && form.touched.daily_cap)}
                      helperText={form.errors.daily_cap && form.touched.daily_cap ? form.errors.daily_cap : null}
                    />
                  )}
                </Field>
                <Field name="working_days">
                  {({ field, form }) => (
                    <Stack direction={'row'} spacing={1}>
                      {['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'].map(
                        (day, index) => (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                checked={field.value.includes(index)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    form.setFieldValue('working_days', [...field.value, index]);
                                  } else {
                                    form.setFieldValue(
                                      'working_days',
                                      field.value.filter((d) => d !== index)
                                    );
                                  }
                                }}
                              />
                            }
                            label={day}
                          />
                        )
                      )}
                    </Stack>
                  )}
                </Field>
                <Field name="countries">
              {({ form }) => (
                <Autocomplete
                  multiple
                  options={countryOptions}
                  getOptionLabel={(option) => option.label}
                  value={form.values.countries.map(code => countryOptions.find(c => c.code === code))}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {option.label}
                    </li>
                  )}
                  onChange={(event, newValue) => {
                    if (newValue.some(item => item.code === 'ALL')) {
                      if (newValue.length === 1 || !form.values.countries.includes('ALL')) {
                        setFieldValue('countries', countryOptions.map(option => option.code));
                      } else {
                        setFieldValue('countries', []);
                      }
                    } else {
                      setFieldValue('countries', newValue.map(item => item.code));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Выберите страны"
                      placeholder="Добавить страну"
                      error={Boolean(form.errors.countries && form.touched.countries)}
                      helperText={form.errors.countries && form.touched.countries ? form.errors.countries : null}
                    />
                  )}
                />
              )}
            </Field>
                <ErrorMessage name="working_days">{(msg) => <FormHelperText error>{msg}</FormHelperText>}</ErrorMessage>
                <FormControlLabel
                  control={
                    <Switch checked={values.is_active} onChange={(e) => setFieldValue('is_active', e.target.checked)} />
                  }
                  label="Активно"
                />
                <FormControlLabel
                  control={
                    <Switch checked={values.skip_duplicate} onChange={(e) => setFieldValue('skip_duplicate', e.target.checked)} />
                  }
                  label="Пропускать дубль?"
                />
                <FormControlLabel
                  control={
                    <Switch checked={values.skip_after_workings} onChange={(e) => setFieldValue('skip_after_workings', e.target.checked)} />
                  }
                  label="Пропускать вне рабочего время или дубль?"
                />
                <FormControlLabel
                  control={
                    <Switch checked={values.generate_email_if_missing} onChange={(e) => setFieldValue('generate_email_if_missing', e.target.checked)} />
                  }
                  label="Генерировать пустую почту?"
                />
                <FormControlLabel
                  control={
                    <Switch checked={values.phone_with_plus} onChange={(e) => setFieldValue('phone_with_plus', e.target.checked)} />
                  }
                  label="Номер телефона с +?"
                />
                <Typography variant="h5" sx={{ mb: 5 }}>
                  Сопоставление статусов
                  </Typography>
                <FieldArray name="statuses">
                  {({ push, remove, form }) => (
                    <>
                      {form.values.statuses.map((field, index) => (
                        <Stack key={index} direction="row" spacing={2}>
                          <FormControl fullWidth>
                            <InputLabel id={`statusLeadType-${index}`}>Тип Статуса</InputLabel>
                            <Field
                              name={`statuses[${index}].status_type`}
                              as={Select}
                              labelId={`statusLeadType-${index}`}
                              label="Тип Статуса"
                            >
                              <MenuItem value={'valid'}>Валид</MenuItem>
                              <MenuItem value={'invalid'}>Невалид</MenuItem>
                              <MenuItem value={'no_answer'}>Недозвон</MenuItem>
                            </Field>
                          </FormControl>
                          <Field
                            name={`statuses[${index}].status_value`}
                            as={TextField}
                            label="Ожидаемое значение"
                            fullWidth
                            variant="outlined"
                          />
                          
                          <Button
                            sx={{ color: 'error.main', width: '50%' }}
                            startIcon={<Iconify icon="eva:trash-2-outline" />}
                            onClick={() => remove(index)}
                          >
                            Удалить
                          </Button>
                        </Stack>
                      ))}
                      <Button
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        onClick={() =>
                          push({
                            id: '',
                            status_type: '',
                            status_value: '',
                          })
                        }
                      >
                        Добавить значение
                      </Button>
                    </>
                  )}
                </FieldArray>

              </Stack>
              
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{ my: 2 }}
                disabled={isSubmitting}
              >
                Обновить
              </LoadingButton>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default EditCrmSettingPage;
