import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  Stack,
  Button,
  CircularProgress,
} from '@mui/material';
import useAuthContext from '../contexts/AuthContext';
// components
import Iconify from '../components/iconify';
import useFetch from '../hooks/useFetch';
// sections
import {
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppConversionRates,
} from '../sections/@dashboard/app';

const periods = [
  { value: 'day', label: 'За день' },
  { value: 'week', label: 'За неделю' },
  { value: 'month', label: 'За месяц' },
  { value: 'all', label: 'За все время' },
];
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const { user, isAdmin } = useAuthContext();

  const [selectedPeriod, setSelectedPeriod] = useState('day');

  const { isLoading, error, data, refetch } = useFetch(`api/dashboard?period=${selectedPeriod}`, 'get');

  const [leadsStatistic, setLeadsStatistic] = useState({ all: 0, success: 0, duplicate: 0, error: 0 });

  const [leadChartData, setLeadChartData] = useState({ success: [], duplicate: [], error: [], labels: [] });

  const [buyerStatistic, setBuyerStatistic] = useState([{ label: '', value: 0 }]);

  const [countryStatistic, setCountryStatistic] = useState([]);

  useEffect(() => {
    if (data) {
      setLeadsStatistic({
        all: data.leads.all,
        success: data.leads.success,
        duplicate: data.leads.duplicate,
        error: data.leads.error,
      });
      setLeadChartData({
        success: data.leads.chartData.success,
        duplicate: data.leads.chartData.duplicate,
        error: data.leads.chartData.duplicate,
        labels: data.leads.chartData.labels,
      });
      if(isAdmin()){
        setBuyerStatistic(data.buyers);
      }
      setCountryStatistic(data.countries);
    }
  }, [data]);

  const handleChangePeriod = (event) => {
    setSelectedPeriod(event.target.value);
  };

  if (error) {
    return (
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ height: 'calc(100vh - 200px)' }}>
          <Typography variant="subtitle2" noWrap>
            {error}
          </Typography>
          <Button onClick={refetch}>Обновить</Button>
        </Stack>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title> Dashboard</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Привет, {user?.name}
        </Typography>
        {isLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              zIndex: 2,
            }}
          >
            <CircularProgress />
          </div>
        )}
        <Box sx={{ mb: 2, mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              value={selectedPeriod}
              onChange={handleChangePeriod}
              displayEmpty
              sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
            >
              {periods.map((period) => (
                <MenuItem key={period.value} value={period.value}>
                  {period.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Все Лиды" total={leadsStatistic.all} icon={'ant-design:team-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Успешные лиды"
              total={leadsStatistic.success}
              color="info"
              icon={'ant-design:check-circle-outlined'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Лиды дубли"
              total={leadsStatistic.duplicate}
              color="warning"
              icon={'fa-solid:clone'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Лиды ошибки"
              total={leadsStatistic.error}
              color="error"
              icon={'ant-design:warning-outlined'}
            />
          </Grid>

          <Grid item xs={12} md={isAdmin() ? 6 : 12} lg={ isAdmin() ? 8 : 12}>
            <AppWebsiteVisits
              title="Статус лидов"
              subheader={periods.find((period) => period.value === selectedPeriod)?.label}
              chartLabels={leadChartData.labels}
              colors={['#1890ff', '#ffc107', '#ff4842']}
              chartData={[
                {
                  name: 'Успешные',
                  type: 'area',
                  fill: 'gradient',
                  data: leadChartData.success,
                },
                {
                  name: 'Дубли',
                  type: 'area',
                  fill: 'gradient',
                  data: leadChartData.duplicate,
                },
                {
                  name: 'Ошибки',
                  type: 'area',
                  fill: 'gradient',
                  data: leadChartData.error,
                },
              ]}
            />
          </Grid>
          {isAdmin() && (
            <Grid item xs={12} md={6} lg={4}>
              <AppCurrentVisits
                title="Арбитражники"
                subheader={periods.find((period) => period.value === selectedPeriod)?.label}
                chartData={buyerStatistic}
              />
            </Grid>
          )}

          <Grid item sx={{ height: 'fit-content' }} xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Страны"
              subheader={periods.find((period) => period.value === selectedPeriod)?.label}
              chartData={countryStatistic}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Источник трафика"
              subheader={periods.find((period) => period.value === selectedPeriod)?.label}
              list={[
                {
                  name: 'FaceBook',
                  value: 0,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 0,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 0,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 0,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
